/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery, graphql } from 'gatsby';

const bgImageQuery = graphql`
  query {
    defaultBcg: directus {
      global {
        backgroundImage {
          id
          imageFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

const StyledHero = ({ img, className, children, home }) => {
  const data = useStaticQuery(bgImageQuery);
  const bgImage =
    img ||
    data.defaultBcg.global.backgroundImage.imageFile.childImageSharp?.fluid;
  return (
    <BackgroundImage className={className} home={home} fluid={bgImage}>
      {children}
    </BackgroundImage>
  );
};

export default styled(StyledHero)`
  min-height: ${props => (props.home ? 'calc(100vh - 62px)' : '50vh')};
  background: ${props =>
    props.home
      ? 'linear-gradient(rgba(63, 208, 212, 0.7), rgba(0, 0, 0, 0.7))'
      : 'none'};
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
`;
